import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "creating-users-training-videos"
    }}>{`Creating Users Training Videos`}</h1>
    <hr></hr>
    <p>{`Learn how to quickly add users to the Content Mangement System with a few simple steps in this tutorial video. `}<em parentName="p">{`Please note that the 'Allow Channel Creation' permission is not covered in this video. This permission relates to allowing the user to change settings of channels that they have access to`}</em>{`:`}</p>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/bjviIOVpTUo?si=XJLYIgxZ4813Y4in" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0: left: 0",
        "width": "90%",
        "height": "90%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      